import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <div>
        <header className="header default header-transparent">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="topbar d-none d-sm-block d-lg-flex align-items-center text-center">
                  <div className="me-3 d-inline-block">
                    <a href="mailto:gethelp@sasskit.com" className="text-dark"><i className="far fa-envelope me-2 text-primary" /><span className="me-1">Email :</span>alpay@mentalsoft.net</a>
                  </div>
                  <div className="me-3 d-inline-block">
                    <a href="#" className="text-dark"><i className="fas fa-headphones me-2 text-primary" /><span className="me-1">Destek:</span>(0532) 333 99 93</a>
                  </div>
                  <div className="me-auto d-inline-block">
                    <a href="#" className="text-dark"><i className="fas fa-location-arrow me-2 text-primary" /><span className="me-1">Adres:</span>Ziyapaşa Mahallesi Baraj Cad. Yeter Apt. A-Blok Kat : 2/4, 01140 Seyhan/Adana</a>
                  </div>
                </div>
                <nav className="navbar navbar-static-top navbar-expand-lg header-sticky justify-content-between py-0 border-top">
                  <a className="navbar-brand" href="index.html"><img className="img-fluid logo" src="images/10.png" alt="logo" /></a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarmenu" aria-controls="navbarmenu" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-align-left" />
                  </button>
                  <div className="navbar-collapse collapse" id="navbarmenu">
                    <ul className="nav navbar-nav">
                      {/* <li className="nav-item">
                        <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Modül Hakkında <i className="fas fa-chevron-down fa-xs" /></a>
                      </li> */}
                      <li className="nav-item">
                        <a href="#Hakkimizda" className="nav-link">Hakkımızda<i className="fas fa-chevron-down fa-xs" /></a>
                      </li>
                      <li className="nav-item">
                        <a href="#mobiluygulama" className="nav-link">Mobil Uygulama<i className="fas fa-chevron-down fa-xs" /></a>
                      </li>
                      <li className="nav-item">
                        <a href="#webmodul" className="nav-link">Web ve Masaüstü Modül<i className="fas fa-chevron-down fa-xs" /></a>
                      </li>
                      <li className="nav-item">
                        <a href="#referanslar" className="nav-link">Referanslar<i className="fas fa-chevron-down fa-xs" /></a>
                      </li>
                      <li className="nav-item">
                        <a href="#iletisim" className="nav-link">İletişim<i className="fas fa-chevron-down fa-xs" /></a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/*=================================
 header */}
        {/*=================================
Banner */}
        <section className="banner banner-02 position-relative overflow-hidden">
          <div id="main-slider" className="container h-900 h-md-700 h-sm-auto ">
            {/* Swiper Slider 1*/}
            <div className="row align-items-center h-100">
              <div className="col-xl-7 col-md-6 mb-4 mb-md-0 order-md-2 z-index-9">
              </div>
              <div className=" col-xl-5 col-lg-6 order-md-1">
                <div className="contant">
                  <h1 className="text-black mb-4">Professiyonelliğe bir adım daha.</h1>
                  <div className="row">
                    <div className="col-xl-6 col-lg-4">
                      <div className="d-flex align-items-center info-box-03">
                        <i className="fa fa-check icon-xl" />
                        <p style={{ color: 'black' }}>Demir & Çelik için doğru seçim</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-4">
                      <div className="d-flex align-items-center info-box-03">
                        <i className="fa fa-check icon-xl" />
                        <p style={{ color: 'black' }}>4 farklı platform da hizmet</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-img d-none d-lg-block">
            <img className="img-fluid" src="images/bg/bg1.png" alt />
          </div>
          <div className="position-absolute top-0 start-0 z-index-n1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={1920} height={1000} preserveAspectRatio="xMidYMid" viewBox="0 0 1920 1000">
              <g transform="translate(960,437.5) scale(1,1) translate(-960,-437.5)"><linearGradient id="lg-0.7760586418593289" x1={0} x2={1} y1={0} y2={0}>
                <stop stopColor="#dcdcdc" offset={0} />
                <stop stopColor="#c8c5c5" offset={1} />
              </linearGradient><path d fill="url(#lg-0.7760586418593289)" opacity="0.4">
                  <animate attributeName="d" dur="9.090909090909092s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="0s" values="M0 0L 0 900.8905903892756Q 160 824.5535425905625 320 787.4317614095728T 640 710.0629483711157T 960 712.0566315090508T 1280 514.8176428429922T 1600 471.689689764393T 1920 458.77364194075716L 1920 0 Z;M0 0L 0 829.346982785176Q 160 850.4831339183378 320 817.3435200292954T 640 724.8364121753284T 960 689.0295326182538T 1280 511.77401741062874T 1600 432.2373222898676T 1920 416.55303634238015L 1920 0 Z;M0 0L 0 912.0190313472932Q 160 841.7725593041725 320 797.5270162908611T 640 717.9426770462146T 960 712.9508320944876T 1280 589.152563848529T 1600 588.8067646130274T 1920 514.4021372100168L 1920 0 Z;M0 0L 0 900.8905903892756Q 160 824.5535425905625 320 787.4317614095728T 640 710.0629483711157T 960 712.0566315090508T 1280 514.8176428429922T 1600 471.689689764393T 1920 458.77364194075716L 1920 0 Z" />
                </path><path d fill="url(#lg-0.7760586418593289)" opacity="0.4">
                  <animate attributeName="d" dur="9.090909090909092s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="-1.8181818181818183s" values="M0 0L 0 827.4544708139075Q 160 866.3876322593079 320 841.3865148102228T 640 779.7578460878027T 960 632.6401904919576T 1280 531.5559145492318T 1600 524.5481251318911T 1920 360.3763359435678L 1920 0 Z;M0 0L 0 828.6933628046206Q 160 775.1224423048847 320 755.0009156960222T 640 742.1020824044992T 960 619.9817119723482T 1280 479.1007363335232T 1600 412.405657832232T 1920 301.8179942657829L 1920 0 Z;M0 0L 0 869.2349991099805Q 160 812.9707797118227 320 773.6346073683211T 640 757.285370327684T 960 702.3265930537362T 1280 563.7997175315782T 1600 488.23619029221726T 1920 415.23408707483486L 1920 0 Z;M0 0L 0 827.4544708139075Q 160 866.3876322593079 320 841.3865148102228T 640 779.7578460878027T 960 632.6401904919576T 1280 531.5559145492318T 1600 524.5481251318911T 1920 360.3763359435678L 1920 0 Z" />
                </path><path d fill="url(#lg-0.7760586418593289)" opacity="0.4">
                  <animate attributeName="d" dur="9.090909090909092s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="-3.6363636363636367s" values="M0 0L 0 940.0688551194796Q 160 877.6485396625238 320 860.4163214315404T 640 701.1931623030107T 960 696.9509969994634T 1280 562.0459862305122T 1600 512.921695699712T 1920 365.17381292660576L 1920 0 Z;M0 0L 0 934.2343007069505Q 160 888.0672542742651 320 843.852490804164T 640 707.2194947306111T 960 589.0177399203335T 1280 507.74864249163153T 1600 417.3623372041752T 1920 329.03528513730026L 1920 0 Z;M0 0L 0 822.9988990032492Q 160 779.567140047843 320 762.0801957283911T 640 752.4764695415993T 960 660.8862186783026T 1280 536.3639741288457T 1600 568.42897974107T 1920 462.20444891583855L 1920 0 Z;M0 0L 0 940.0688551194796Q 160 877.6485396625238 320 860.4163214315404T 640 701.1931623030107T 960 696.9509969994634T 1280 562.0459862305122T 1600 512.921695699712T 1920 365.17381292660576L 1920 0 Z" />
                </path><path d fill="url(#lg-0.7760586418593289)" opacity="0.4">
                  <animate attributeName="d" dur="9.090909090909092s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="-5.454545454545455s" values="M0 0L 0 861.2630442121725Q 160 818.5199984963992 320 796.751327336783T 640 706.2948390516999T 960 605.0933624826438T 1280 540.2676888839555T 1600 457.9734755207567T 1920 399.57031683975663L 1920 0 Z;M0 0L 0 840.7632225646071Q 160 761.9637420837286 320 741.2157367168404T 640 766.5452632174729T 960 606.3305897293782T 1280 503.5625569266228T 1600 530.1359653505746T 1920 459.42495325945214L 1920 0 Z;M0 0L 0 881.6034955422762Q 160 817.2083012821154 320 782.3245519138361T 640 768.2395148540494T 960 614.496648733741T 1280 585.7238500465095T 1600 478.6454802933941T 1920 424.08065226070863L 1920 0 Z;M0 0L 0 861.2630442121725Q 160 818.5199984963992 320 796.751327336783T 640 706.2948390516999T 960 605.0933624826438T 1280 540.2676888839555T 1600 457.9734755207567T 1920 399.57031683975663L 1920 0 Z" />
                </path><path d fill="url(#lg-0.7760586418593289)" opacity="0.4">
                  <animate attributeName="d" dur="9.090909090909092s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="-7.272727272727273s" values="M0 0L 0 825.0928695123232Q 160 824.7898455452686 320 784.7418150373871T 640 697.2128284129802T 960 626.8251781638456T 1280 625.5626219943902T 1600 498.00215651048825T 1920 394.8100914318972L 1920 0 Z;M0 0L 0 904.204311314168Q 160 826.7247312413047 320 802.398074466922T 640 732.0554401823182T 960 721.9076568036322T 1280 654.1870451967795T 1600 490.7364381114258T 1920 393.9709317654504L 1920 0 Z;M0 0L 0 917.0412818431565Q 160 768.3114894802418 320 743.4640267726511T 640 714.9909203433641T 960 647.7888739223863T 1280 608.593382367915T 1600 447.9851245848818T 1920 318.76755070440186L 1920 0 Z;M0 0L 0 825.0928695123232Q 160 824.7898455452686 320 784.7418150373871T 640 697.2128284129802T 960 626.8251781638456T 1280 625.5626219943902T 1600 498.00215651048825T 1920 394.8100914318972L 1920 0 Z" />
                </path></g>
            </svg>
          </div>
        </section>

        {/*=================================
Banner */}
        {/*=================================
left-svg */}

        <section className="space-ptb position-relative">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-10">
                <div className="section-title text-center">
                  <h6 className="sub-title text-primary">Modül İçeriği</h6>
                  <h2 className="title">Hakkında</h2>
                  <p>Demir & Çelik sektöründe hizmet veren Demir6 modülü kullanıcıların Web,Masaüstü,IOS ve Android üzerinde bilgileri görmelerini sağlar.</p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 mb-4 mb-lg-0 position-relative">
                <img className="img-fluid vert-move pe-0 pe-xl-5" src="images/about/img-01.png" alt />
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="feature-info-style-01 rounded bg-primary-light d-sm-flex">
                  <div className="feature-icon">
                    <i className="flaticon-shield text-primary rounded-circle" />
                  </div>
                  <div className="feature-contant">
                    <h6 className="mt-0 fw-bold">+20 Referans</h6>
                    <p className="mb-0">Demir & Çelik sektöründe bulunan bulunanların demir6 tercih ediyor.</p>
                  </div>
                </div>
                <div className="feature-info-style-01 rounded bg-warning-light d-sm-flex mt-4 ms-sm-5">
                  <div className="feature-icon">
                    <i className="flaticon-review text-warning rounded-circle" />
                  </div>
                  <div className="feature-contant">
                    <h6 className="mt-0 fw-bold">Müşteri Memnuniyeti</h6>
                    <p className="mb-0">Hata durumunda veya soru sormak için bize ulaşabilirsiniz”.</p>
                  </div>
                </div>
                <div className="feature-info-style-01 rounded d-sm-flex bg-success-light mt-4">
                  <div className="feature-icon">
                    <i className="flaticon-infographic text-success rounded-circle" />
                  </div>
                  <div className="feature-contant">
                    <h6 className="mt-0 fw-bold">Verimlilik Arttırma</h6>
                    <p className="mb-0">Demir6 modülünü kullanarak bilgilerin kesinliğini sağlar ve işlemleri hızlandırır.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
category */}
        {/*=================================
category */}
        <section id="mobiluygulama" className="space-ptb position-relative">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-8">
                <div className="section-title">
                  <h6 className="sub-title text-primary">Mobil Uygulama</h6>
                  <h2 className="title">IOS & Android içerisinden görüntüler</h2>
                  <p>Uygulamayı telefondan kullanarak tüm bilgileri görebilir ve güncelleme yapabilirsiniz.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-center mt-0 mt-xl-7 mt-lg-8 position-relative z-index-9">
              <div className="col-12 text-center">
                <div className="position-relative">
                  <div className="owl-carousel   text-left owl-dots-dark" data-nav-dots="true" data-items={5} data-md-items={4} data-sm-items={3} data-xs-items={2} data-xx-items={1} data-space={0}>
                    <div className="item">
                      <img className="img-fluid center-block mx-auto px-3 d-block" src="images/mobile-app/m1.png" alt="Mobile app" />
                    </div>
                    <div className="item">
                      <img className="img-fluid center-block mx-auto px-3 d-block" src="images/mobile-app/m2.png" alt="Mobile app" />
                    </div>
                    <div className="item">
                      <img className="img-fluid center-block mx-auto px-3 d-block" src="images/mobile-app/m3.png" alt="Mobile app" />
                    </div>
                    <div className="item">
                      <img className="img-fluid center-block mx-auto px-3 d-block" src="images/mobile-app/m4.png" alt="Mobile app" />
                    </div>
                    <div className="item">
                      <img className="img-fluid center-block mx-auto px-3 d-block" src="images/mobile-app/m5.png" alt="Mobile app" />
                    </div>
                    <div className="item">
                      <img className="img-fluid center-block mx-auto px-3 d-block" src="images/mobile-app/m6.png" alt="Mobile app" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute bottom-0 end-0 z-index-n1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={2174} height={675} preserveAspectRatio="xMidYMid" viewBox="0 0 2174 675">
              <g transform="translate(1087,337.5) scale(-1,-1) translate(-1087,-337.5)"><linearGradient id="lg-0.34371840626581496" x1={0} x2={1} y1={0} y2={0}>
                <stop stopColor="#6a26da" offset={0} />
                <stop stopColor="#6a26da" offset={1} />
              </linearGradient><path d fill="url(#lg-0.34371840626581496)" opacity={1}>
                  <animate attributeName="d" dur="10s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="0s" values="M0 0L 0 559.5839699345004Q 217.4 591.4856892055925  434.8 550.8054006940279T 869.6 453.7330203959557T 1304.4 462.7395205736912T 1739.2 346.55994547066985T 2174 330.0875202772016L 2174 0 Z;M0 0L 0 571.0636311133754Q 217.4 603.0200496190861  434.8 581.1314456318779T 869.6 479.6633174054623T 1304.4 461.6489300138165T 1739.2 356.8598264398067T 2174 268.5390028212365L 2174 0 Z;M0 0L 0 578.6496701020452Q 217.4 547.0346798200696  434.8 518.1105999779284T 869.6 421.8015226906417T 1304.4 451.92288238736546T 1739.2 358.26805920192726T 2174 246.7722362684059L 2174 0 Z;M0 0L 0 559.5839699345004Q 217.4 591.4856892055925  434.8 550.8054006940279T 869.6 453.7330203959557T 1304.4 462.7395205736912T 1739.2 346.55994547066985T 2174 330.0875202772016L 2174 0 Z" />
                </path><path d fill="url(#lg-0.34371840626581496)" opacity="0.7">
                  <animate attributeName="d" dur="10s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="-5s" values="M0 0L 0 564.2375393977508Q 217.4 592.7894511823711  434.8 575.9404691234519T 869.6 503.8777694794604T 1304.4 444.92369775772124T 1739.2 394.45990405809783T 2174 344.2458687168049L 2174 0 Z;M0 0L 0 580.7908785067649Q 217.4 616.6439494279883  434.8 587.3437545005595T 869.6 421.5625268937185T 1304.4 442.9385128952018T 1739.2 283.88389622758893T 2174 331.9235477526699L 2174 0 Z;M0 0L 0 668.7744276508382Q 217.4 623.7785455238735  434.8 604.4059694748277T 869.6 490.84045767436527T 1304.4 408.5846488907162T 1739.2 315.6235176612797T 2174 229.2381799042472L 2174 0 Z;M0 0L 0 564.2375393977508Q 217.4 592.7894511823711  434.8 575.9404691234519T 869.6 503.8777694794604T 1304.4 444.92369775772124T 1739.2 394.45990405809783T 2174 344.2458687168049L 2174 0 Z" />
                </path></g>
            </svg>
          </div>
        </section>
        <section id="webmodul" className="space-pt position-relative">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div className="section-title text-center">
                  <h6 className="sub-title text-primary">Sizi İleri taşır.</h6>
                  <h2 className="title">Firmaya özel raporlar</h2>
                  <p>Tüm yükleme,satış ve alış hareketleri için özel raporlar</p>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-start justify-content-center">
              <div className="col-lg-4 col-md-5 col-12 order-md-2">
                <div className="nav tabs nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <a className="nav-link active d-flex" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className="flaticon-infographic rounded-circle" /><div className="contant"> <h6>Rapor Ve Analiz</h6><p className="mb-0">Günlük ve aylık raporlar.</p></div></a>
                  <a className="nav-link d-flex" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i className="flaticon-review rounded-circle" /><div className="contant"> <h6>İletişim Kolaylığı</h6><p className="mb-0">Saniyeler içerisinde bilgiye erişmek.</p></div></a>
                  <a className="nav-link d-flex" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i className="flaticon-shield rounded-circle" /><div className="contant"> <h6>Kolay Ulaşım</h6><p className="mb-0">Modüle nerede olursanız olun ulaşabilirsiniz.</p></div></a>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 col-12 mt-4 mt-md-0">
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active text-center" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"><img className="img-fluid" src="images/about/tab2.png" alt /></div>
                  <div className="tab-pane fade text-center" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><img className="img-fluid" src="images/about/tab3.png" alt /></div>
                  <div className="tab-pane fade text-center" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><img className="img-fluid" src="images/about/tab6.png" alt /></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginBottom: "110px" }} className="action-box space-pt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-4 mb-lg-0 text-center">
                <img className="img-fluid" src="images/services/img5.png" alt />
              </div>
              <div className="col-lg-6">
                <div className="section-title">
                  <h6 className="sub-title text-primary">Nasıl Çalışıyoruz?</h6>
                  <h2 className="title">En hızlı ve kaliteli hizmeti size sunuyoruz</h2>
                  <p>Bize ulaştıktan sonra Demir6 modülünü 7 iş günü içerisinde web,masaüstü,IOS & Android olarak kullanmaya başlıyabilirsiniz.</p>
                </div>
                <div className="feature-step-timeline">
                  <div className="feature-step">
                    <div className="feature-step-number">
                      1
                      <span className><i className="fas fa-check" /></span>
                    </div>
                    <div className="feature-info-content">
                      <h6 className="title">Firmaya Özel Tasarım</h6>
                      <p>Yeni tasarım ve yeni özellikler için bize ulaşabilirsiniz.</p>
                    </div>
                  </div>
                  <div className="feature-step">
                    <div className="feature-step-number">
                      2
                      <span className><i className="fas fa-check" /></span>
                    </div>
                    <div className="feature-info-content">
                      <h6 className="title">Kolay Kullanım</h6>
                      <p>Kısa bir kullanım eğitiminden sonra tüm çalışanlar modülü kullanmaya hazır hale gelicektir.</p>
                    </div>
                  </div>
                  <div className="feature-step">
                    <div className="feature-step-number">
                      3
                      <span className><i className="fas fa-check" /></span>
                    </div>
                    <div className="feature-info-content">
                      <h6 className="title">Mail Hizmeti</h6>
                      <p className="mb-0">Size özel mail hizmeti sunuyoruz.Firma isminizle mail gönderebilirsiniz</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*=================================
category */}
        {/*=================================
banner */}
        {/* <section className="space-pb position-relative bg-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mb-4 mb-lg-0 position-relative overflow-hidden">
                <img className="img-fluid rotate-animation" src="images/bg/11.png" alt />
              </div>
              <div className="col-md-6">
                <div className="section-title">
                  <h4 className="title">Who we are and what we do get to know us better.</h4>
                  <p>Making a decision to do something – this is the first step. We all know that nothing moves until someone makes a decision. The first action is always in making the decision to proceed. This is a fundamental step, which most people overlook.</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check pt-1 text-primary" /><span>For those of you who are serious about having more.</span></li>
                      <li className="d-flex mb-4"><i className="fas fa-check pt-1 text-primary" /><span>This is the beginning of creating the life that you want to live.</span></li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check pt-1 text-primary" /><span>Success is something of which we all want more.</span></li>
                      <li className="d-flex mb-4"><i className="fas fa-check pt-1 text-primary" /><span>Commitment – understanding the price and having the willingness.</span></li>
                    </ul>
                  </div>
                </div>
                <a href="#" className="btn btn-primary mt-3">Get Started <i className="fa fa-angle-right ps-3" /></a>
              </div>
            </div>
          </div>
        </section> */}
        {/*=================================
footer*/}
        {/*=================================
Recent work */}


        <section id="referanslar" className="space-ptb">
          <div className="container">
            <div className="row mb-5 mb-md-4">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>Referanslarımıza Göz Atabilirsiniz</h2>
                  <p>Firma İsimlerine Tıklayarak Projelere Ulaşabilirsiniz.</p>
                </div>
              </div>
              <div className="col-lg-5 offset-xl-1 text-lg-end text-start">
                <a className="btn btn-primary mt-lg-3 mt-0">Referanslar <i className="fa fa-angle-right ps-3" /></a>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="owl-carousel text-left" data-nav-dots="true" data-items={5} data-lg-items={4} data-md-items={3} data-sm-items={2} data-xs-items={2} data-xx-items={1}>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/ensdemir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://ensdemir.online" target="_blank"><center>ENS Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg">
                        <img className="img-fluid" src="images/portfolio/damgademir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://damgadc.online" target="_blank"><center>Damga Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg">
                        <img className="img-fluid" src="images/portfolio/cemdemir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://cemdemir.online" target="_blank"><center>Cem Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/mehmetali.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://mehmetalidc.online" target="_blank"><center>MehmetAli DC</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/ilhanmetal.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://ilhanmetal.online" target="_blank"><center>İlhan Metal</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/ozdemir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://ozdemirdc.online" target="_blank"><center>Özdemir DC</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/yukseltas.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://yukseltas.online" target="_blank"><center>Yükseltaş DC</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/gencmet.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary"><center>Gençmet DC</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/gulluoglu.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://gulluoglumetal.online" target="_blank"><center>Güllüoğlu DC</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/bozkoyunlu.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://bozkoyunlu.online" target="_blank"><center>Bozkoyunlu DC</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <a href='https://yuserdemir.online'>
                        <img className="img-fluid" src="images/portfolio/yuser.jpg" alt />
                        </a>
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://yuserdemir.online" target="_blank"><center>Yuser Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/nayir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://nayirdemir.online" target="_blank"><center>Nayir Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/detas.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://detas.online" target="_blank"><center>Detas</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/oguz.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://oguzlardemircelik.com" target="_blank"><center>Oğuzlar Filmaşin</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/karadeniz.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://karadenizdemir.online" target="_blank"><center>Karadeniz Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/koraysan.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://koraysan.online" target="_blank"><center>Koraysan Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/ozer.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://ozerdc.online" target="_blank"><center>Özer Group</center></a>
                      </div>
                    </div>
                  </div>
                   
                  
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/ilhanlar.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://ilhanlardemir.com/" target="_blank"><center>İlhanlar Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/zms.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary"><center>Zms Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/koksal.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary"><center>Köksal Yapı</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/lunisolar.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://lunisolar.online/" target="_blank"><center>Lunisolar</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/ilhancelik.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://ilhancelik.online/" target="_blank"><center>İlhan Çelik</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/kaandemir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://kaandemir.online/" target="_blank"><center>Kaan Demir</center></a>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="portfolio portfolio-style-02">
                      <div className="portfolio-img portfolio-lg bg-holder">
                        <img className="img-fluid" src="images/portfolio/askindemir.jpg" alt />
                      </div>
                      <div className="portfolio-info">
                        <a className="portfolio-title font-weight-bold text-primary" href="https://askindemir.online/" target="_blank"><center>Aşkın Demir</center></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=================================
Recent work */}
        {/*=================================
banner */}
        <section id="Hakkimizda" className="space-pb position-relative">
          <div className="container">
            <div className="row align-items-center py-5 position-relative z-index-1">
              <div className="col-lg-6 ps-lg-5">
                <div className="section-title">
                  <h6 className="sub-title text-primary">Hakkımızda</h6>
                  <h2 className="title">Kısaca Kendimizden Bahsedelim.</h2>
                  <p>Mentalsoft Mart 2004 te yazılım ve bilişim teknolojileri alanında hizmet vermek üzere kurulmuştur. Üretim sistemleri ve yönetimi, tekstil-konfeksiyon ve üretim sektörü proje danışmanlığı, bilişim yöneticiliği, muhasebe ve finans sistemleri, performans ölçüm sistemleri, MIS (management information systems) yöneticiliği alanlarında uzun yıllar görev yapmış profesyonel bir kadro tarafından kurulan Mentalsoft, bir MENTAL oluşum iştirakidir. 2014 yılından itibaren Demir6 Modülünü geliştirmeye başladık ve devam ediyoruz</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>Web Tasarım.</span></li>
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>B2B Modülleri.</span></li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>ERP Modülleri.</span></li>
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>Stok Takip Modülleri.</span></li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>Çok Şubeli Kullanıma Uygundur.</span></li>
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>Tüm Ticari Modüllerle Entegrasyon.</span></li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>Kantar Entegrasyon.</span></li>
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>Sınırsız Kullanıcı.</span></li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list list-unstyled">
                      <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span>SMS ve Whatsapp Gönderimi.</span></li>

                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list list-unstyled">

                    </ul>
                  </div>
                </div>
                <a className="btn btn-primary mt-3">Detaylar <i className="fa fa-angle-right ps-3" /></a>
              </div>
              <div className="col-lg-6 mt-4 mt-md-5 mt-lg-0">
                <div className="owl-carousel testimonial text-left owl-dots-dark" data-nav-dots="true" data-items={1} data-md-items={1} data-sm-items={1} data-xs-items={1} data-xx-items={1}>
                  <div className="item text-center">
                    <div className="testimonial-item shadow rounded">
                      <div className="testimonial-content">
                        <div className="rating mb-3">
                          <ul className="list-unstyled">
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                          </ul>
                        </div>
                        <p className="mb-4">Yıllardan gelen tecrübelerim ile Mentalsoft için +100 proje yapmış bulunmaktayım.Demir6 modülünün geliştirilmesine yardım etmekteyim</p>
                      </div>
                      <div className="testimonial-info d-block">
                        <div className="testimonial-avatar-img mx-auto">
                          <img className="img-fluid avatar w-auto rounded-circle" src="images/avatar/01.jpg" alt />
                        </div>
                        <div className="testimonial-author">
                          <h6 className="author-name">- Atakan ORAL</h6>
                          <span className="author-designation">Web Tasarım</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item text-center">
                    <div className="testimonial-item shadow rounded">
                      <div className="testimonial-content">
                        <div className="rating mb-3">
                          <ul className="list-unstyled">
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                            <li><i className="fas fa-star text-warning" /></li>
                          </ul>
                        </div>
                        <p className="mb-4">Demir6 modülünün tüm firmalara özel olarak yapılan +50 den fazla raporların geliştirilmesine yardım etmekteyim.</p>
                      </div>
                      <div className="testimonial-info d-block">
                        <div className="testimonial-avatar-img mx-auto">
                          <img className="img-fluid avatar w-auto rounded-circle" src="images/avatar/02.jpg" alt />
                        </div>
                        <div className="testimonial-author">
                          <h6 className="author-name">Ayhan Dağlık</h6>
                          <span className="author-designation">Proje Yönetimi</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SVG START*/}
          <div className="position-absolute top-0 end-0 translate-middle-y">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="1200px" width="1200px" viewBox="0 0 288 288">
              <linearGradient id="PSgrad_1" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                <stop offset="0%" stopColor="#f5f9ff" stopOpacity={1} />
                <stop offset="100%" stopColor="#f5f5f5" stopOpacity={1} />
              </linearGradient>
              <path fill="url(#PSgrad_1)" d>
                <animate repeatCount="indefinite" attributeName="d" dur="5s" values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z; M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6 c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8 C48.9,198.6,57.8,191,51,171.3z; M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z " />
              </path>
            </svg>
          </div>
          {/* SVG END*/}
        </section>

        {/*=================================
Pricing */}
        {/*=================================
accordion */}
        <section className="space-ptb position-relative">
          <div className="position-absolute">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="158.406" height="804.844" viewBox="0 0 158.406 804.844">
              <g>
                <path fill className="fill-light" d="M-1.003,31.615 C-1.003,31.615 42.816,82.751 24.145,231.540 C24.145,231.540 5.755,392.915 53.918,455.131 C53.918,455.131 327.801,784.971 -11.319,804.838 L-284.134,803.699 L-258.004,485.752 L-279.882,68.286 L-211.096,-0.003 L-1.003,31.615 Z" />
                <path d="M123.518,366.964 C117.593,378.100 105.543,383.882 93.729,382.367 C90.914,382.007 88.111,381.231 85.408,380.015 C84.811,379.747 84.218,379.455 83.634,379.144 C77.748,376.013 73.357,371.173 70.750,365.560 C66.986,357.462 66.933,347.756 71.451,339.265 C72.943,336.461 74.822,333.998 76.987,331.906 C85.932,323.260 99.761,320.927 111.335,327.084 C125.713,334.733 131.168,352.588 123.518,366.964 ZM107.869,333.598 C99.191,328.982 88.820,330.731 82.116,337.213 C80.493,338.783 79.084,340.629 77.966,342.731 C74.578,349.097 74.618,356.373 77.441,362.445 C79.396,366.653 82.687,370.282 87.099,372.629 C87.539,372.863 87.983,373.081 88.431,373.282 C90.457,374.194 92.558,374.777 94.669,375.046 C103.526,376.181 112.560,371.847 117.003,363.497 C122.738,352.719 118.649,339.333 107.869,333.598 Z" className="cls-2 fill-primary" />
                <path d="M70.006,695.801 L66.179,699.624 L59.000,692.450 L51.820,699.624 L47.994,695.801 L55.173,688.626 L47.994,681.452 L51.820,677.629 L59.000,684.803 L66.179,677.629 L70.006,681.452 L62.826,688.626 L70.006,695.801 Z" className="cls-2 fill-primary" />
              </g>
            </svg>
          </div>
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-6 pe-2 pe-md-5">
                <div className="section-title">
                  <h6 className="sub-title text-primary">Merak Edilenler</h6>
                  <h2 className="title">Sorularınızın Cevapları</h2>
                  <p>Aklınıza takılan soruların cevaplarını aşağıda bulabilir ve daha fazlası için bize ulaşabilirsiniz.</p>
                </div>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        01) Kurulum Süreci Hakkında
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        <p>Anlaşma yapıldıktan sonra 3 ila 7 iş günü içerisinde bütün Demir6 modüllerini kullanabilirsiniz.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        02) Destek Almak Hakkında
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        <p>Kurumsal ve kişisel telefonlarımız tüm sorularınlarınız çözmek ve yardım etmek için hazır beklemektedir.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        03)  Sorun Çözüm Hakkında.
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        <p>Aklınıza takılan herhangi sorun veya bir istek olduğunda bize ulaşabilir ve en hızlı şekilde çözüm alabilirsiniz</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 position-relative">
                <img className="img-fluid vert-move" src="images/services/img4.png" alt />
              </div>
            </div>
          </div>
        </section>
      </div>
      <section id="iletisim" className="space-pb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-title text-center">
                <h6 className="sub-title text-primary">İletişim</h6>
                <h2 className="title">Bize ulaşın ve detayları Konuşalım</h2>
                <p>Aklınıza takılan veya merak ettiğiniz bir soru olursa bir telefon kadar uzağınızdayız.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="feature-info py-4 rounded text-center h-100">
                <div className="feature-icon mb-4">
                  <i className="fas fa-phone-alt text-white bg-praimry rounded-circle" />
                </div>
                <h6 className="my-3 fw-bold">Telefon</h6>
                <p className="mb-0">(0532) 333 99 93</p>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="feature-info py-4 rounded text-center h-100">
                <div className="feature-icon mb-4">
                  <i className="far fa-envelope text-white bg-danger rounded-circle" />
                </div>
                <h6 className="my-3 fw-bold">Email</h6>
                <p className="mb-0">alpay@mentalsoft.net</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-info py-4 rounded text-center h-100">
                <div className="feature-icon mb-4">
                  <i className="fas fa-paper-plane text-white bg-warning rounded-circle" />
                </div>
                <h6 className="my-3 fw-bold">Adres</h6>
                <p className="mb-0">Ziyapaşa Mahallesi Baraj Cad. Yeter Apt. A-Blok Kat : 2/4, 01140 Seyhan/Adana</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*=================================
footer*/}
      {/*=================================
How it works*/}

      {/*=================================
How it works*/}
      {/*=================================
footer*/}

      <svg className="blog-shape" xmlns="http://www.w3.org/2000/svg" width="100%" height={200} viewBox="0 0 1920 100">
        <path className fill="#6a26da" d="M0,80S480,0,960,0s960,80,960,80v20H0V80Z" /></svg>
      <footer className="footer bg-primary pt-5">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-12 col-lg-4 pe-lg-5 mb-5 mb-lg-0">
              <a className="footer-logo py-0" href="index.html"><img className="img-fluid" src="images/11.png" alt="logo" /></a>
              <div className="footer-contact-info mt-4">
                <p className="text-white">Bizlere mail adresimizden ulaşabilirsiniz.</p>
                <form className="form-light">
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email*" />
                  </div>
                  <a className="btn btn-dark">Mail Gönder</a>
                </form>
              </div>
            </div>
            <div className="col-lg-5 col-md-8">
              <div className="row">
                <div className="col-sm-6 col-lg-6 mb-4 mb-sm-0">
                  <h5 className="text-white mb-2 mb-sm-4">Sayfalar</h5>
                  <div className="footer-link">
                    <ul className="list-unstyled mb-0">
                      <li><a className="text-white" href="#">Modül Hakkında</a></li>
                      <li><a className="text-white" href="#">Hakkımızda</a></li>
                      <li><a className="text-white" href="#">Mobil Uygulama</a></li>
                      <li><a className="text-white" href="#">Web Modül</a></li>
                      <li><a className="text-white" href="#">Masaüstü Modül</a></li>
                      <li><a className="text-white" href="#">Referanslar</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6 mb-4 mb-lg-0">
                  <h5 className="text-white mb-2 mb-sm-4">Hizmetlerimiz</h5>
                  <div className="footer-link">
                    <ul className="list-unstyled mb-0">
                      <li><a className="text-white" href="#">Demir Çelik Modülü</a></li>
                      <li><a className="text-white" href="#">B2B Modülü</a></li>
                      <li><a className="text-white" href="#">Web Tasarım</a></li>
                      <li><a className="text-white" href="#">Stok Takip</a></li>
                      <li><a className="text-white" href="#">Barkod Programı</a></li>
                      <li><a className="text-white" href="#">Kantar Programı</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 mb-lg-0">
              <h5 className="text-white mb-2 mb-sm-4">Sosyal Medya</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><a className="text-white" href="#">İnstagram</a></li>
                  <li><a className="text-white" href="#">Facebook</a></li>
                  <li><a className="text-white" href="#">Linkedn</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-md-3 col-sm-8">
              <div className="footer-image position-relative">
                <img className="img-fluid p-4 pb-0 position-absolute bottom-0 end-0" src="images/svg/marketing.svg" alt />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom footer-border-top py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6  mt-0">
                <p className="mb-0 text-white">©Copyright 2023 <a className="text-white" href="https://mentalsoft.net">Mentalsoft</a> Tüm Hakları Saklıdır</p>
              </div>
              <div className="col-lg-6 text-end">
                <div className="social-icon">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><i className="fab fa-twitter" /></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                    <li><a href="#"><i className="fab fa-instagram" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*=================================
footer*/}
      {/*=================================
Back To Top*/}
      <div id="back-to-top" className="back-to-top">
        <a href="#"> <i className="fas fa-angle-up" /></a>
      </div>
    </div>


  );
}

export default App;
